

/* 576 is sm col */
@media (max-width: 576px) {
  
  ul.list-table.pf-notification-list, ul.list-table.pf-notification-list li {
    display: block !important;
  }

  ul.list-table.pf-notification-list li:first-child {
    margin-bottom: 10px;
  }

  ul.list-table.pf-notification-list li button {
    width: 100%;
  }
  
  ul.list-table.pf-notification-list li:last-child {
    padding-left: 0 !important;
    border: 0 !important;
  }
}