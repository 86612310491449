.pf-task-wrap .ant-card-body {
  padding: 0 0 24px 0;
}
.pf-task-wrap .ant-card {
  border-radius: 5px;
}
/* Task list, actions on top */
.pf-task-list {
  margin-bottom: 3px;
  padding: 8px 24px;
  list-style: none;
}

.pf-task-list li:first-child {
  float: left;
}

.pf-task-list li:first-child .ant-checkbox {
  margin-top:2px;
}

.pf-task-list li:first-child .ant-checkbox-inner {
  height: 20px;
  width: 20px;
  border-color: #ddd;
}

.pf-task-list li:first-child .ant-checkbox-wrapper:hover .ant-checkbox-checked:after {
  border-color: #ddd;
}

.pf-task-list li:first-child .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ddd;
  border-color: #ddd;
}

.pf-task-list-right {
  text-align: right;
}

.pf-task-list li {
  display: inline-block;
  padding-right: 15px;
  font-size: 11px;
}

.pf-task-list li a {
  color: rgb(0,0,0,65%);
}

.pf-task-list li:first-child {
  padding-left: 0;
}

.pf-task-list li:last-child {
  padding-right: 0;
}

/* Task para, ellipsis */
.pf-task-para {
  display: -webkit-box;
  margin-bottom: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

p.pf-task-para.pf-task-para-show {
  display: block;
  white-space: pre-line;
  overflow: visible;
}

.pf-task-indicator {
  z-index: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 5px;
  border-top-left-radius: 5px;
  background-color: #006AFF;
}

.pf-task-indicator-complete {
  background-color: #87d068;
}

.pf-task-indicator-overdue {
  background-color: #f5222d;
}

.pf-task-row {
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.pf-task-row p {
  font-size: 14px;
  word-break: break-word;
}

/* Task upload collapse/panel */
.pf-task-upload-collapse {
}

.pf-task-upload-panel {
  background-color: #f8f8f8;
  border-radius: 8px;
  /* marginBottom: 24, */
  border: 0px;
  overflow: hidden;
}

.pf-task-upload-panel .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.pf-task-panel.pf-task-upload-panel > div {
  padding: 0 0 0 15px !important;
}

.pf-task-panel.pf-task-upload-panel > div > i {
  left: 0px !important;
}

.pf-task-panel.pf-task-upload-panel > .ant-collapse-content {
  padding-left: 0 !important;
}

.pf-task-upload, .pf-task-upload .ant-upload.ant-upload-select {
  display: block;
}

.pf-task-upload {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 8px;
}

.pf-task-upload .ant-upload-list {
  /* padding-top: 10px; */
}

.pf-task-upload .ant-upload-list-item:first-child {
  margin-top: 0;
}

.pf-task-upload .ant-upload-list-item-info {
  border-radius: 3px;
  font-size: 12px;
}

.pf-task-upload  .anticon-paper-clip {
  top: 4px;
  font-size: 12px;
}

.pf-task-upload .anticon-delete {
  padding-top: 5px;
}

.pf-task-comments {
  padding: 0;
  border: 1px solid #e8e8e8;
  border-top-style: dashed;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pf-task-comments a {
  font-size: 11px;
}

.pf-task-panel {
  padding: 0;
  border: 0 !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  background-color: white;
  font-size: 12px;
}

.pf-task-panel > div {
  padding: 5px 20px !important;
  color: #575757 !important;
}

.pf-task-panel > div > i {
  left: 5px !important;
}

.pf-task-panel .ant-collapse-content {
  padding-top: 0 !important;
}

.pf-task-panel .ant-collapse-content-box {
  padding-top: 0 !important;
  padding-left: 0;
  padding-right: 0;
}

.pf-task-comment-hr {
  margin: 0;
  border: 1px solid #f5f5f5;
  border-style: solid;
  border-bottom: 0;
}

.pf-task-comment-content {
  padding-top: 25px;
}

/* Comment bubble content below */
.pf-task-comment-bubble {
  position: relative;
  width: 50%;
  margin-bottom: 10px;
  padding: 6px 8px;
  border-radius: 8px;
}

.pf-task-comment-bubble-user {
  position: relative;
  left: 100%;
  margin-left: -50%;
  border-bottom-right-radius: 0;
  background-color: #f0f5ff;
  border: 1px solid #e1ebfd;
}

.pf-task-comment-bubble-opposing {
  background-color: #f1f2f5;
  border: 1px solid #ebebf0;
  border-bottom-left-radius: 0;
}

.pf-comment-bubble-details {
  margin-bottom: 5px;
  font-size: 8px;
  color: #888;
}

.pf-task-comment-details-user {
  position: relative;
  left: 100%;
  margin-left: -49%;
}

.pf-comment-bubble-text {
  white-space: pre-line;
}

/* PF Task Add */
.pf-card-add-task {
  border-radius: 5px;
}

.pf-task-date-wrap {
  padding: 10px;
  border-radius: 5px;
  background-color: #f8f8f8;
}

.pf-task-date-wrap > span {
  width: 100%;
}

.pf-task-grabber {
  display: block;
  position: absolute;
  top: 50%;
  left: 4px;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  opacity: .5;
  cursor: grab;
}

.pf-task-grabber:active {
  cursor: grabbing;
}

/* 576 is sm col */
@media (max-width: 576px) {

  .pf-task-list {
    margin-left: 10px;
    text-align: left;
  }

  .pf-task-col-pad-mobile {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  /* .ant-upload-list-item-name {
  
  } */

  .pf-task-comment-bubble {
    width: 100%;
    left: auto;
    margin: auto;
    margin-bottom: 10px;
  }

  .pf-task-comment-details-user {
    left: 0;
    margin-left: 0;
  }

  .pf-task-col-mobile > div {
    margin: 0;
  }

  .pf-task-btn-mobile {
    width: 100%;
  }

  .pf-task-grabber {
    display: none;
  }

  /* .pf-task-upload .ant-upload-list div {
    
  } */

}
