html body {
  background-color: #f7f7f7;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* lt. grey background #f7f7f7 */
/* box-shadow: 0 2px 4px -1px rgba(28,36,58,.1), 0 4px 5px 0 rgba(28,36,58,.07), 0 1px 10px 0 rgba(28,36,58,.06); */
.boxshadow {
  /* box-shadow: 0 2px 4px -1px rgba(28,36,58,.1), 0 4px 5px 0 rgba(28,36,58,.07), 0 1px 10px 0 rgba(28,36,58,.06); */
  box-shadow: 0 2px 8px #ddd;
}

.cardshadow {
  box-shadow: 0 1px 1px #eee;
}

.header {
  height: 56px;
}

.flex {
  display: flex;
}

ul.list-inline {
  padding-left: 0;
  margin-bottom: 0;
}

ul.list-inline li {
  display: inline;
  list-style: none;
}

ul.list-table {
  display: table;
  padding-left: 0;
  margin-bottom: 0;
}

ul.list-table li {
  display: table-cell;
  vertical-align: middle;
  list-style: none;
}

ul.list-breadcrumb {
  margin-bottom: 25px;
  padding: 0;
  list-style: none;
}

.list-breadcrumb li {
  display: inline-block;
  padding: 0 10px;
  border-left: 1px solid #e8e8e8;
}

.list-breadcrumb li:first-child {
  padding-left: 0;
  border-left: 0 ;
}

.cursor-pointer {
  cursor: pointer;
}

.pf-general-text-block {
  margin-bottom: 30px;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.ant-btn-danger {
  background-color: #f5222d;
  border-color: #f5222d;
}

.pf-modal {
  width: 70% !important;
  max-width: 968px;
}

/* 576 is sm col */
@media (max-width: 576px) {
  .pf-modal {
    top:0;
    width: 100% !important;
    margin-top: 0;
    max-width: 100%;
  }
}
