.pf-statistic .ant-statistic-title {
  margin-bottom: 2px;
  font-size: 12px;
  /* color: rgba(255,255,255,.5); */
  color: rgba(0,0,0,.5);
}

.pf-statistic .ant-statistic-content {
  font-size: 16px;
  /* color: rgba(255,255,255,.65); */
  color: rgba(0,0,0,.65);
}

.pf-property-collapse {
  border-radius: 5px;
  /* background-color: rgba(0,0,0,.04); */
  background-color: rgba(255,255,255,.8);
}

.pf-property-collapse .ant-collapse-header {
  /* color: rgba(255,255,255,.65) !important;   */
  color: rgba(0,0,0,.65) !important;
}

.pf-property-collapse .ant-collapse-header {
  padding-left: 25px !important;
}

.pf-property-collapse i {
  left: 8px !important;
} 

.pf-property-collapse > div {
  padding: 0 !important;
  border-bottom: 0 !important;
}

.pf-property-description {
  padding: 10px 0;
  border-radius: 5px;
}

.pf-property-description .ant-descriptions-title {
  /* color: rgba(255,255,255,.3); */
  color: rgba(0,0,0,.7);
}

.pf-property-description .ant-descriptions-item-label, .pf-property-description .ant-descriptions-item-content {
  /* color: rgba(255,255,255,.65) !important; */
  color: rgba(0,0,0,.65) !important;
}

.pf-property-description .ant-descriptions-title {
  margin-bottom: 5px;
  font-size: 14px;
}

.pf-property-card-details {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
  /* background-color: #001751; */
  background-color: #f8f8f8;
}

/* 576 is sm col */
@media (max-width: 576px) {

  .pf-property-resp-col a {
    display: block;
    margin-bottom: 12px;
    border-right: 0 !important;
    /* font-size: 12px; */
  }
  .pf-property-resp-col button {
    display: block;
    margin-bottom: 12px;
    margin-left: 0 !important;
  }

  .pf-property-resp-col button:last-child {
    margin-bottom: 0;
  }

  .pf-property-resp-progress {
    margin-bottom: 10px;
  }

  .pf-property-resp-progress:last-child {
    margin-bottom: 0;
  }

  ul.list-table.pf-property-list-task, ul.list-table.pf-property-list-task li, ul.list-table.pf-property-list-task ul.list-table {
    display: block !important;
  }

  ul.list-table.pf-property-list-task li:first-child {
    margin-bottom: 10px;
    text-align: center;
  }

  ul.list-table.pf-property-list-task li:first-child button {
    width: 100%;
  }

  ul.list-table.pf-property-list-task li:last-child {
    padding-left: 0 !important;
    border: 0 !important;
  }

}

