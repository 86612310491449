.pf-auth-collapse {
  margin-bottom: 24px;
  background-color: transparent;
}

.pf-auth-collapse .ant-collapse-item {
  border-bottom: 0;
}

.pf-auth-collapse .ant-collapse-header {
  padding: 0px !important;
  padding-left: 16px !important;
  color: #006AFF !important;
}

.pf-auth-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: -2px !important;
}

.pf-auth-collapse .ant-collapse-content-box {
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
}