.pf-messages-queue {
  max-height: 100vh;
  border: 1px solid #f8f8f8;
  border-top: 1px solid #f8f8f8;
  overflow-y: scroll;
}

.pf-message-queue-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 12px;
  border-radius: 3px;
  background-color: #f8f8f8;
}

.pf-heading-message {
  margin-left: 10px;
}

.pf-messages-queue-date {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #8c8c8c;
  font-size: 10px;
}

.pf-messages-queue-name {
  font-size: 11px;
  font-weight: 300;
}

.pf-messages-property-name {
  position: relative;
  top: -3px;
  font-size: 10px;
  font-weight: 300;
}

.pf-messages-card {
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0;
  cursor: pointer;
}

.pf-messages-card-active {
  background-color: #fff;
}

.pf-messages-card-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: #006AFF;
  height: 100%;
  width: 3px;
}

.pf-messages-card h4 {
  margin-bottom: 0;
}

.pf-messages-card > div {
  padding: 15px 20px;
}

.pf-messages-badge {
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -11px;
}

.pf-messages-icon-replied {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -2px;
  color: #d9d9d9;
  transform: scale(1, -1);
}

.pf-messages-display {
  position: relative;
  margin-left: 10px;
  padding: 0 12px;
  overflow-y: scroll;
}

.pf-messages-display p {
  padding-left: 33px;
}

.pf-messages-conversation-display {
  margin-bottom: 15px;
  padding-top: 15px;
  border-top: 1px solid #f8f8f8;
  overflow-y: scroll;
  white-space: pre-line;
}

.pf-messages-conversation-display::before {
  content: '';
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 1px;
  top: -16px;
  background-color: #fff;
  box-shadow: 0px -5px 10px 13px #fff;
}

.pf-message-conversation {
  position: relative;
  margin-bottom: 25px;
  padding: 0 10px;
}

.pf-messages-delete {
  position: absolute;
  right: 10px;
  color: #8c8c8c;
  cursor: pointer;
}

.pf-messages-coversation-heading-date {
  position: absolute;
  top: 22px;
  right: 10px;
  font-size: 10px;
  font-weight: 300;
}

.pf-messages-coversation-date {
  font-size: 10px;
  font-weight: 300;
}

.pf-messages-coversation-name {
  font-size: 11px;
}

.pf-messages-textarea {
  position: relative;
}

.pf-messages-textarea::before {
  content: '';
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 1px;
  top: -16px;
  background-color: #fff;
  box-shadow: 0px -5px 10px 13px #fff;
}


/* 768 is md col */
@media (max-width: 768px) {

  .pf-messages-queue {
    max-height: 248px;  
  }

  .pf-heading-message {
    margin-top: 18px;
    margin-left: 0;
  }

  .pf-messages-coversation-heading-date {
    position: relative;
    top: -6px;
    left: 0;
  }
}
