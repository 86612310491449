.pf-footer-list {
  margin-bottom: 10px !important;
}

.pf-footer-list li {
  padding-right: 10px;
}

.pf-footer-list a {
  color: #595959;
  text-decoration: underline;
}

.pf-footer-list a:hover {
  opacity: .7;
}

.pf-footer-text {
  color: #595959;
}


.pf-dashboard-main {
  padding: 25px;
}

.pf-dashboard-wrap {
  height: 100%;
  padding: 25px; 
  border-radius: 5px;
  background-color: #fff;
}

.ant-layout-footer {
  padding: 0px 50px 24px;
}

/* 576 is sm col */
@media (max-width: 576px) {
  .pf-dashboard-main {
    padding: 0px;
  }

  .pf-dashboard-wrap {
    padding: 5px;
  }

  .pf-dashboard-wrap > h3 {
    padding-top: 10px;
    padding-left: 10px;
  }

  .ant-layout-footer {
    padding: 24px 25px;
  }
}
