.pf-sidebar {
  background-color: #001751;
  z-index: 1;
}

.pf-sb-logo-standalone {
  position: relative;
  top: 3px;
  display: block;
  margin: auto;
  width: 30px;
  max-width: 30px;
  min-width: 30px;
}

.pf-sb-logo-horizontal {
  display: block;
  margin: auto;
  max-width: 165px;
  min-width: 25%;
  top: 10px;
  position: relative;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(0,0,0,.7);
  opacity: .7;
}

.pf-sidebar ul {
  background-color: #001751;
  color: rgba(255,255,255,.7);
}

.pf-sidebar ul .ant-menu-item-disabled {
  color: rgba(0,0,0,1) !important;
}

.pf-sidebar .ant-layout-sider-trigger {
  background-color: #001751;
  color: rgba(255,255,255,.7)
}

.ant-layout-sider-trigger {
  border-right: 1px solid #e8e8e8;
}

.ant-menu-inline {
  border-right: 0;
}

.pf-sidebar-badge {
  top: 6px;
  position: absolute;
  margin-left: -15px;
}

.pf-sidebar-badge > sup {
  position: absolute;
  left: 4px;
  min-width: 6px;
  min-height: 6px;
  width: 6px;
  height: 12px;
}

.pf-sidebar-badge > sup > span {
  top: -4px;
  left: 3px;
  position: absolute;
  font-size: 8px;
}

.pf-sidebar-badge > sup > span + span {
  left: 7px;
}

.pf-sidebar-badge > sup > span + span + span {
  left: 11px;
}

.pf-sidebar-mobile-expand, .pf-sidebar-overlay {
  display: none;
}

/* 576 is sm col */
@media (max-width: 576px) {

  .pf-sidebar {
    position: fixed;
    z-index: 101;
    left: -80px;
    border-right-color: transparent !important;
  }

  .pf-sidebar-overlay {
    display: block;
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
  }

  .pf-sidebar.pf-sidebar-expanded {
    left: 0;
  }

  .ant-layout-sider-trigger {
    border-right-color: transparent;
  }

  .pf-sidebar-mobile-expand {
    display: block;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 51px;
    height: 40px;
    width: 35px;
    background-color: #001751;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  .pf-sb-logo-horizontal {
    min-width: 85%;
  }

  .pf-sidebar-mobile-expand i {
    position: relative;
    top: 10px;
    right: -9px;
    font-size: 16px;
    font-weight: bold;
  }

}
