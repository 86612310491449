


.pf-auth-wrap {
  top: auto;
}

.auth-reg .ant-card-head {
  background-color: #f7f7f7;
}

.pf-auth-logo {
  display: block;
  width: 160px;
  margin: 15px auto 15px;
}

@media (min-width: 767px) {
  
  .pf-auth-wrap {
    position: relative;
    top: 100px;
    top: 20vh;
    margin: auto;

    max-width: 365px;
  }

  .pf-auth-logo {
    display: block;
    width: 170px;
    margin: auto auto 25px;
  }
  .auth-reg {
    border-radius: 5px;
    /* box-shadow: 0 2px 4px -1px rgba(28,36,58,.1), 0 4px 5px 0 rgba(28,36,58,.07), 0 1px 10px 0 rgba(28,36,58,.06); */
    box-shadow: 0 2px 8px #ddd;
  }

  .auth-reg .ant-card-head {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

}